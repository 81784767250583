import React from 'react'
import styled from 'styled-components'
import { useMatch } from '@reach/router'
import { Link } from 'gatsby'
import { Button } from '@foundation-lib/ui'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'
import Reviews from './Reviews'
import itemTypes from '../../../data/itemTypes'

const Img = styled.img`
  width: 250px;
`

const Container = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`

const Body = styled.div`
  margin-left: 20px;
  @media (max-width: 800px) {
    margin-left: 0px;
    margin-top: 20px;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const Details = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 15px;
`

const Option = styled(p => (p.active ? <div {...p} /> : <Link {...p} />))`
  display: inline-block;
  margin-left: 7px;
  ${p => p.active && 'font-weight: bold;'}

  & + & {
    border-left: 1px solid #000;
    padding-left: 7px;
  }
`

const H1 = styled.h1`
  & span {
    font-weight: normal;
    font-size: 16px;
    margin-left: 7px;
    @media (max-width: 500px) {
      display: none;
    }
  }
`

const getTypeLabel = type => itemTypes.find(t => t.name === type)?.label || type

const ProductDetailPageBody = ({
  title,
  image,
  name,
  children,
  slug,
  options,
  reviews,
}) => {
  const { selectedOptionType } =
    useMatch('products/:product/:selectedOptionType') || {}
  const sendCartItemListAction = useCartItemList(s => s.action)
  const selectedOption =
    options.find(({ type }) => type === selectedOptionType) || options[0]
  const { price, type, weightOz, preorder } = selectedOption
  const addToCart = () => {
    const item = {
      label: title,
      name,
      price,
      qty: 1,
      weightOz,
      slug,
      preorder,
      ...(options.length > 1 ? { type } : {}),
    }
    sendCartItemListAction({ action: 'add', item })
  }

  return (
    <Container>
      <div>
        <Img src={image} alt={title} />
      </div>
      <Body>
        <H1>
          {title} <span>{getTypeLabel(type)}</span>
        </H1>
        {options.length > 1 && (
          <div style={{ marginLeft: '-7px' }}>
            {options.map(o => (
              <Option
                to={`${slug.replace(/\/$/, '')}/${o.type}`}
                active={o === selectedOption}
              >
                {getTypeLabel(o.type)}
              </Option>
            ))}
          </div>
        )}
        <Details>
          {reviews.length ? (
            <b style={{ display: 'block', marginBottom: '15px' }}>
              <a href="#reviews">Read reviews ({reviews.length})</a>
            </b>
          ) : null}
          <b>Price:</b> ${parseFloat(price).toFixed(2)}{' '}
          {preorder ? ' (Preorder)' : ''}
        </Details>
        <Button size="large" onClick={addToCart}>
          Add to cart
        </Button>
        <br />
        <br />
        <hr />
        {children}
        {reviews.length ? <Reviews product={name} reviews={reviews} /> : null}
      </Body>
    </Container>
  )
}

export default ProductDetailPageBody
