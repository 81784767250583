import React from 'react'
// import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'
import { GatsbyProductDetailPage } from '../features/product-detail'

const Product = ({ data }) => {
  const {
    id,
    html,
    frontmatter: { featuredimage, ...other },
    fields: { slug },
  } = data.product

  const reviews = data.reviews.edges.map(
    ({ node: { fields, frontmatter, ...otherFields } }) => ({
      ...fields,
      ...frontmatter,
      ...otherFields,
    }),
  )

  return (
    <GatsbyProductDetailPage
      id={id}
      {...other}
      slug={slug}
      image={featuredimage?.childImageSharp?.fixed?.src}
      reviews={reviews}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </GatsbyProductDetailPage>
  )
}

export default Product

export const pageQuery = graphql`
  query ProductByID($id: String!, $name: String) {
    product: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        ...ItemFrontmatterFieldsFragment
        ...BookImageFragment
      }
    }
    reviews: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "review" }, product: { eq: $name } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            name
            reviewerName
            reviewerLocation
            title
          }
        }
      }
    }
  }
`
