import React from 'react'
import { StandardPage } from 'src/features/templates'
import Helmet from 'react-helmet'
import ProductDetailPageBody from './ProductDetailPageBody'

const GatsbyProductDetailPage = ({ title, description, ...other }) => (
  <StandardPage>
    <Helmet titleTemplate="%s | Products">
      <title>{`${title}`}</title>
      <meta name="description" content={`${description}`} />
    </Helmet>
    <ProductDetailPageBody {...{ title, description, ...other }} />
  </StandardPage>
)

export default GatsbyProductDetailPage
