import React from 'react'
import { compact } from 'lodash'
import styled from 'styled-components'

const List = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`

const Review = styled.li`
  border: 1px solid #dbd6cd;
  border-radius: 0.5rem;
  padding: 20px;

  h4,
  p {
    margin-top: 0px;
  }

  & + & {
    margin-top: 30px;
  }
`

const Reviews = ({ reviews }) => (
  <>
    <h2 id="reviews">Reviews</h2>
    <List>
      {reviews.map(r => (
        <Review>
          {r.title && <h4>{r.title}</h4>}
          <div dangerouslySetInnerHTML={{ __html: r.html }}></div>~{' '}
          {compact([r.reviewerName, r.reviewerLocation]).join(' from ')}
        </Review>
      ))}
    </List>
  </>
)

export default Reviews
